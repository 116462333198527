<template>
  <div class="main-body">
    <div class="table-operator">
      <a-space>
        <a-upload v-model:file-list="fileList" name="file" :action="uploadAction" :headers="headers" @change="handleChange">
          <a-button>
            <upload-outlined></upload-outlined>
            会员导入
          </a-button>
        </a-upload>

        <a-button type="primary" @click="showCreateUserLabelModal()">会员导入模板</a-button>
      </a-space>
    </div>

    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="文件名称">
          <a-input v-model:value="formState.file_name" placeholder="文件名称" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table rowKey="label_id" :columns="columns" :data-source="data" :pagination="Refpagination" @change="handleRefresh">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateUserLabelModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
    <create-user-label ref="RefCreateUserLabel" @handleSubmit="handleRefresh" />
    <update-user-label ref="RefUpdateUserLabel" @handleSubmit="handleRefresh" />
  </div>
</template>
<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, reactive, ref, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/views/user/api";
import CreateUserLabel from "./modules/label/CreateUserLabel.vue";
import UpdateUserLabel from "./modules/label/UpdateUserLabel.vue";
import * as XLSX from "xlsx";
const columns = [
  {
    title: "上传时间",
    dataIndex: "create_time",
    width: "10%",
  },
  {
    title: "导入会员数",
    dataIndex: "user_num",
    width: "12%",
  },
  {
    title: "成功数",
    dataIndex: "success_num",
    width: "10%",
  },
  {
    title: "失败数",
    dataIndex: "error_num",
    width: "10%",
  },
  {
    title: "文件名称",
    dataIndex: "file_name",
    width: "10%",
  },
  // {
  //   title: "操作",
  //   dataIndex: "action",
  //   width: "20%",
  // },
];

export default defineComponent({
  components: {
    CreateUserLabel,
    UpdateUserLabel,
  },
  setup() {
    const formState = reactive({
      label_name: "",
      grade_id: 0,
      betweenTime: "",
    });

    //上传文件
    const uploadAction = Api.upload;

    const headers = {
      authorization: "authorization-text",
    };

    const gradeList = ref([{ name: "默认等级", grade_id: 0 }]);

    const data = ref([]);
    const Refpagination = ref({});

    // 添加用户等级
    const RefCreateUserLabel = ref();
    const showCreateUserLabelModal = () => {
      Api.user_import_file().then((res) => {
        ToDoExcel(res.data.filename, res.data.title, res.data.data);
      });
      // RefCreateUserLabel.value.showFromModal();
    };
    const ToDoExcel = (excelName, titleArr, dataArr) => {
      var filename = excelName + ".xlsx"; //文件名称
      var data = [titleArr, ...dataArr]; //数据，一定注意需要时二维数组
      var ws_name = "Sheet1"; //Excel第一个sheet的名称
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, ws_name); //将数据添加到工作薄
      XLSX.writeFile(wb, filename);
    };

    // 编辑用户
    const RefUpdateUserLabel = ref();
    const showUpdateUserLabelModal = (item) => {
      RefUpdateUserLabel.value.showFromModal(item);
    };

    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.user_import_record(formState).then((res) => {
        Refpagination.value.current = res.data.list.current_page;
        Refpagination.value.pageSize = res.data.list.per_page;
        Refpagination.value.total = res.data.list.total;
        data.value = res.data.list.data;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.label_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户标签后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.user_label_del({ id: record.label_id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
    });

    return {
      value1: ref(),
      formState,
      gradeList,
      columns,
      data,
      Refpagination,
      RefCreateUserLabel,
      RefUpdateUserLabel,
      showCreateUserLabelModal,
      showUpdateUserLabelModal,
      showDeleteConfirm,
      handleRefresh,
      uploadAction,
      headers,
      // ToDoExcel
    };
  },
});
</script>